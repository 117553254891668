import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { OneLinkWrapper } from '../pages/one-link/OneLinkWrapper'
import { ATMWrapper } from '../pages/one-link copy/OneLinkWrapper'
import { BIWrapper } from '../pages/one-link copy 2/OneLinkWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { STRINGS } from "../../utils/Enums";

const PrivateRoutes = () => {
    const ProcessDetailPage = lazy(() => import('../modules/processDetail/ProcessDetailPage'))
    const ProcessDetailPageWD = lazy(() => import('../modules/processDetail copy/ProcessDetailPage'))
    const ProcessDetailPageBI = lazy(() => import('../modules/processDetail copy 2/ProcessDetailPage'))
    // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route path='oneLink/IBFT' element={<OneLinkWrapper />} />
                <Route path={`${STRINGS.ROUTES.ATM_WD}`} element={<OneLinkWrapper />} />
                <Route path={`${STRINGS.ROUTES.BI}`} element={<OneLinkWrapper />} />
                <Route path={`${STRINGS.ROUTES.UBP_LINK}`} element={<OneLinkWrapper />} />
                <Route path={`${STRINGS.ROUTES.PP_LINK}`} element={<OneLinkWrapper />} />
                <Route path='builder' element={<BuilderPageWrapper />} />
                <Route path='menu-test' element={<MenuTestPage />} />
                {/* Lazy Modules */}
                <Route
                    path={`${STRINGS.ROUTES.ONE_LINK_PROCESS_DETAIL}/*`}
                    element={
                        <SuspensedView>
                            <ProcessDetailPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path={`${STRINGS.ROUTES.ATM_WD_PROCESS_DETAIL}/*`}
                    element={
                        <SuspensedView>
                            <ProcessDetailPageWD />
                        </SuspensedView>
                    }
                />
                <Route
                    path={`${STRINGS.ROUTES.BI_PROCESS_DETAIL}/*`}
                    element={
                        <SuspensedView>
                            <ProcessDetailPageBI />
                        </SuspensedView>
                    }
                />
                {/*<Route*/}
                {/*    path='crafted/pages/profile/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ProfilePage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/pages/wizards/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WizardsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/widgets/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <WidgetsPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='crafted/account/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <AccountPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='apps/chat/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <ChatPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/*<Route*/}
                {/*    path='apps/user-management/*'*/}
                {/*    element={*/}
                {/*        <SuspensedView>*/}
                {/*            <UsersPage/>*/}
                {/*        </SuspensedView>*/}
                {/*    }*/}
                {/*/>*/}
                {/* Page Not Found */}
                {/* <Route path='*' element={<Navigate to='/dashboard'/>}/> */}
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
