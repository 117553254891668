import { createSlice } from '@reduxjs/toolkit';
import { getCurrentProcessType, logError } from '../../utils/DefaultFunctions';
import { STRINGS } from '../../utils/Enums';
import { API } from '../../utils/services';


export const uploadFileRequest = (formData, selectedId) => (dispatch) => {
    // console.log("uploadFile =>", formData)
    dispatch(requestPending(true))
    dispatch(uploadFileSuccess({
        data: {
            data: {
                fileUploadPending: true,
            }
        }, selectedId
    }));
    API.post('/api/ProcessFileUpload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
        // validateStatus: (err) => {
        //     console.log("globalSlice", err)
        //     if (err === 400) {
        //         dispatch(uploadFileSuccess({
        //             data: {
        //                 data: {
        //                     isError: true,
        //                     message: 'File format is invalid for file Bpc Acquirer File'
        //                 }
        //             }, selectedId
        //         }));
        //     }
        //     if(err === 201){
        //         return '';
        //     }
        // }
    }).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.CREATE) {
            res.data.fileUploadPending = false;
            dispatch(uploadFileSuccess({ data: res.data, selectedId }));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // console.log("globalSlice", JSON.parse(JSON.stringify(ex)));
        let error = JSON.parse(JSON.stringify(ex));
        // alert(error.message);
        // dispatch(setFileUploadPending(false))
        if (error.status === 400) {
            dispatch(uploadFileSuccess({
                data: {
                    data: {
                        isError: true,
                        message: error.message,
                        fileUploadPending: false
                    }
                }, selectedId
            }));
        }
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const startProcess = (data) => (dispatch) => {
    dispatch(requestPending(true));
    API.post(`/api/ProcessRun/Start`, data).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessRunStart(res.data));
            // alert(res.data.message)
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong");
        logError(ex);
        dispatch(requestPending(false));
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessById = (id) => (dispatch) => {
    API.get(`/api/ProcessFile/${id}`,).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessFilesList(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong")
        logError(ex);
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessDetailById = (id) => (dispatch) => {
    dispatch(setResponsePending(true))
    dispatch(setDetailResponsePending(true))
    API.get(`/api/ProcessRun/${id}`,).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessDetails(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong")
        logError(ex);
        dispatch(setResponsePending(false));
        dispatch(setDetailResponsePending(false))
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessRunsById = (id) => (dispatch) => {
    dispatch(setResponsePending(true))
    API.get(`/api/ProcessRuns/${id}`,).then((res) => {
        console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessRunsList(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong")
        logError(ex);
        dispatch(setResponsePending(false))
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const getProcessRunDataByType = (id, type, pageIndex, pageSize) => (dispatch) => {
    dispatch(resetProcessRunDataByType())
    API.get(`/api/ProcessRun/${id}/${type}/ProcessRunData?PageIndex=${pageIndex}&PageSize=${pageSize}`,).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(setProcessRunsDataList(res.data));
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong")
        openSnackBar({ open: true, message: "abc" });
        // logError(ex);
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};
export const changeStatus = (payload) => (dispatch) => {
    let current_id = window.location.pathname.split('/')[4];
    let type = getCurrentProcessType(window.location.pathname.split('/')[3]);
    API.post(`/api/ProcessRun/ChangeStatus`, payload).then((res) => {
        // console.log("globalSlice", res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
            dispatch(statusChangedSuccess(res.data));
            dispatch(getProcessRunDataByType(current_id, type, STRINGS.PAGE_INDEX, STRINGS.PAGE_SIZE));
            dispatch(getProcessDetailById(current_id));
            // alert(res.data.message);
            openSnackBar({ open: true, message: res.data.message });
        } else {
            // console.log("GetUpload",res)
        }
    }).catch((ex) => {
        // console.log("globalSlice", ex)
        // alert("Something went wrong")
        logError(ex);
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
    });
};

export const setUpCurrentProcessId = (payload) => (dispatch) => {
    dispatch(setCurrentProcessId(payload));
}

export const resetProcessRunDataByType = (payload) => (dispatch) => {
    dispatch(resetRunData(payload));
}

export const confirmation = (payload) => async (dispatch) => {
    dispatch(confirmDialog(payload));
};
export const openSnackBar = (payload) => async (dispatch) => {
    console.log("openSnack", payload)
    dispatch(setSnackBar(payload));
};
export const storeCurrentFetchData = (payload) => async (dispatch) => {
    dispatch(setCurrentFetchData(payload));
};
export const resetUploadResponse = (payload) => async (dispatch) => {
    dispatch(resetUploadFileResponse(payload));
};
export const resetRunProcessStart = (payload) => async (dispatch) => {
    dispatch(resetProcessRunStart(payload));
};
export const resetPFilesList = (payload) => async (dispatch) => {
    dispatch(resetProcessFilesList(payload));
};
export const resetProcessRunsListing = (payload) => async (dispatch) => {
    dispatch(resetProcessRunsList(payload));
};

const initialState = {
    isError: false,
    message: '',
    variant: '',
    selectedId: '',
    isPending: false,
    isResponsePending: false,
    isDetailResponsePending: false,
    currentProcessId: '',
    currentProcessTitle: "",
    startProcessSuccess: false,
    // fileUploadPending: false,
    confirmationText: '',
    openConfirmationDialog: false,
    fileUploadFailed: false,
    fileUploadSuccess: null,
    currentFetchData: [],
    processFileIds: [],
    processFilesLists: [],
    processRunsList: [],
    processRunsData: [],
    processDetails: {},
    pageIndex: 1,
    pageSize: 10,
    currentTotalAmount: 0,
    currentTotalRecords: 0,
    currentListProcessCount: 0,
    isStatusChangedSuccess: null,
    snackProps: {
        open: false,
        duration: 4000,
        message: '',
        action: false,
        actionLbl: 'Submit',
    },
};

const globalSlice = createSlice({
    name: 'globalSlice',
    initialState,
    reducers: {
        ErrorAlert: (state, { payload }) => {
            state.isError = true;
        },
        requestPending: (state, { payload }) => {
            state.isPending = payload;
        },
        setResponsePending: (state, { payload }) => {
            state.isResponsePending = payload;
        },
        setDetailResponsePending: (state, { payload }) => {
            state.isDetailResponsePending = payload;
        },
        selectStatus: (state, { payload }) => {
            state.selectedBox = payload;
        },
        setDeviceIp: (state, { payload }) => {
            state.deviceIPAddress = payload.data.ip;
        },
        setCurrentProcessId: (state, { payload }) => {
            console.log("IBFT =>>>", payload)
            state.currentProcessId = payload.currentProcessId;
            state.currentProcessTitle = payload.currentProcessTitle;
        },
        setProcessDetails: (state, { payload }) => {
            state.processDetails = payload.data;
            state.isResponsePending = false;
            state.isDetailResponsePending = false;
        },
        setCurrentFetchData: (state, { payload }) => {
            state.currentFetchData = payload;
        },
        statusChangedSuccess: (state, { payload }) => {
            // console.log("globalSlice", payload)
            state.isStatusChangedSuccess = true;
        },
        setFileUploadPending: (state, { payload }) => {
            // console.log("globalSlice", payload)
            // state.fileUploadPending = payload;
        },
        uploadFileSuccess: (state, { payload }) => {
            // state.fileUploadPending = false;
            state.fileUploadSuccess = true;
            state.fileData = payload.data;
            state.selectedId = payload.selectedId;
            state.processFileIds = [...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId), {
                process: payload.data.data,
                selected_id: payload.selectedId,
            }];
            state.isPending = false;
        },
        setProcessFilesList: (state, { payload }) => {
            state.processFilesLists = payload.data;
            state.currentListProcessCount = payload.data.filter((mp) => mp.isMandatory).length;
        },
        resetProcessFilesList: (state, { payload }) => {
            state.processFilesLists = payload;
            state.processFileIds = payload;
        },
        setProcessRunsList: (state, { payload }) => {
            state.processRunsList = payload.data;
            state.isResponsePending = false;
        },
        resetProcessRunsList: (state, { payload }) => {
            state.processRunsList = [];
        },
        setProcessRunsDataList: (state, { payload }) => {
            console.log("setProcessRunsDataList", payload)
            state.processRunsData = payload.data;
            state.pageIndex = payload.pageIndex;
            state.pageSize = payload.pageSize;
            state.currentTotalAmount = payload.totalAmount;
            state.currentTotalRecords = payload.totalRecords;
            state.isResponsePending = false;
        },
        resetRunData: (state, { payload }) => {
            state.processRunsData = [];
        },
        setProcessRunStart: (state, { payload }) => {
            state.isPending = false;
            state.startProcessSuccess = true;
        },
        resetProcessRunStart: (state, { payload }) => {
            state.startProcessSuccess = false;
        },
        uploadBulkFilesSuccess: (state) => {
            state.fileBulkUploadSuccess = true;
        },
        resetUploadFileResponse: (state, { payload }) => {
            state.fileUploadFailed = payload.failed;
            state.fileUploadSuccess = null;
            state.PO_ID = null;
        },
        resetUploadBulkFilesResponse: (state) => {
            state.fileBulkUploadSuccess = false;
        },
        setGeneratedOtp: (state, { payload }) => {
            state.otp_generated = payload === 0
        },
        setVerifiedOtp: (state, { payload }) => {
            state.otp_verified = payload === 0
        },
        resetOtp: (state) => {
            state.otp_verified = null;
            state.otp_generated = null;
        },
        confirmDialog: (state, { payload }) => {
            state.confirmationText = payload.message;
            state.openConfirmationDialog = payload.open;
        },
        setSnackBar: (state, { payload }) => {
            state.snackProps = {
                open: payload.open,
                message: payload.message,
                duration: payload.duration === undefined ? 4000 : state.snackProps.duration,
                action: payload.action !== undefined ? payload.action : state.snackProps.action,
                actionLbl: payload.actionLbl !== undefined ? payload.actionLbl : state.snackProps.actionLbl,
            };
        },
    },
});

export const {
    setResponsePending,
    requestPending,
    selectStatus,
    confirmDialog,
    setSnackBar,
    uploadFileSuccess,
    setFileUploadPending,
    resetUploadFileResponse,
    uploadBulkFilesSuccess,
    resetUploadBulkFilesResponse,
    setVerifiedOtp,
    setProcessFilesList,
    resetProcessFilesList,
    setProcessRunsList,
    resetProcessRunsList,
    setProcessRunsDataList,
    setProcessRunStart,
    resetProcessRunStart,
    setProcessDetails,
    resetOtp,
    statusChangedSuccess,
    setCurrentFetchData,
    setCurrentProcessId,
    resetRunData,
    setDetailResponsePending,
} = globalSlice.actions;

export default globalSlice.reducer;

