import { STRINGS } from "./Enums";

export function getCurrentDate() {
    const date = new Date();
    // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
    const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    return convertedDate;
}


export function convertISODate(st) {
    const date = new Date(st);
    // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
    const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    return convertedDate;
}

export function formatAMPM(st) {
    let date = new Date(st)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
export function SecondsToTime(st) {

    console.log("SecondsToTime", st);
    if (st === "") return;
    if (st === undefined) return;
    if (st === null) return;
    let hours = `${st.split('')[0]}${st.split('')[1]}`;
    let minutes = `${st.split('')[2]}${st.split('')[3]}`;
    let seconds = `${st.split('')[4]}${st.split('')[5]}`;
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // var sec_num = parseInt(st, 10); // don't forget the second param
    // var hours = Math.floor(sec_num / 3600);
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // var minutes = Math.floor((sec_num) / 60);
    // var seconds = sec_num - (minutes * 60);

    // if (hours < 10) { hours = "0" + hours; }
    // if (minutes < 10) { minutes = "0" + minutes; }
    // if (seconds < 10) { seconds = "0" + seconds; }

    return `${hours + ':' + minutes + ':' + seconds} ${hours >= 12 ? 'PM' : 'AM'}`;
}

export function convertISOTime(st) {
    const time = st !== undefined ? st.split('T')[1] : '00:00';
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function processingTime(s, e) {
    let startDate = new Date(s);
    let endDate = new Date(e);
    let min = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (60 * 1000));
    let sec = Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / 1000);
    // let today = new Date(s);
    // let end = new Date(e);
    // let EndDate = new Date(today.getFullYear() + `-${(end.getMonth()+1)}-${end.getDate()}`);
    // let diffMs = (today - EndDate); // milliseconds between now & EndDate
    // let diffDays = Math.floor(diffMs / 86400000); // days
    // let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // console.log("diffMs",diffMs)
    // console.log("diffMs", min)
    // console.log("diffMs =>", sec)
    return min === 0 ? `${sec} - sec` : `${min} - min`
}

export function convertLink(link) {
    if (link === undefined || link === '') return '';

    let path = link;
    // let path2 = path.replace(/\\/g, "/");
    // const filePath = path2.split('/Upload/')[1];
    // const filePath = path2.split('/65.21.231.108/')[1];
    // console.log("download_link",  path2);
    // const basePath = window.location.hostname === 'recon.3em.tech' || window.location.hostname === 'localhost' ? `http://recon.3em.tech/upload/` : `http://apiuatrecon.samba.com.pk:8080/upload/`;
    // const basePath = window.location.hostname === 'recon.3em.tech' || window.location.hostname === 'localhost' ? `http://api.recon.3em.tech/` : `http://apiuatrecon.samba.com.pk:8080/`;
    // console.log("download_link",  `${basePath}${filePath}`)
    // return `${basePath}${filePath}`
    return `${path}`
}
// export function convertExportLink(link) {
//     if (link === undefined || link === '') return '';
//     console.log("convertExportLink",link)
//     let path = link;
//     let path2 = path.replace(/\\/g, "/");
//     const filePath = path2.split('/websites/')[1];
//     const basePath = window.location.hostname === 'recon.3em.tech' || window.location.hostname === 'localhost' ? `http://recon.3em.tech/upload/` : `http://apiuatrecon.samba.com.pk:8080/upload/`;
//     console.log("convertExportLink",`${basePath}${filePath}`)
//     return `${basePath}${filePath}`
// }

export function logError(err) {
    return console.log("Error =>", err);
}

//getCurrentType
export function getCurrentProcessType(type) {
    let value = '';
    if (type === 'un_match') {
        value = STRINGS.MATCH_TYPE.UN_MATCH
    }
    if (type === 'hold_over') {
        value = STRINGS.MATCH_TYPE.HOLD_OVER
    }
    if (type === 'exception') {
        value = STRINGS.MATCH_TYPE.EXCEPTION
    }
    if (type === 'force_match') {
        value = STRINGS.MATCH_TYPE.FORCE_MATCH
    }
    if (type === 'saf') {
        value = STRINGS.MATCH_TYPE.SAF
    }
    return value;
}



//getCurrentProcessId
export function getCurrentProcessId(id) {
    let value = '';
    if (id === STRINGS.IBFT_ID) {
        value = 'IBFT'
    }
    if (id === STRINGS.BI_ID) {
        value = 'BI'
    }
    if (id === STRINGS.WD_ID) {
        value = 'ATM_WD'
    }
    if (id === STRINGS.UBP_ID) {
        value = 'UBP'
    }
    if (id === STRINGS.PP_ID) {
        value = 'PP'
    }
    return value;
}

export function getCurrentProcessIdReverse(id) {
    let value = '';
    if (id === 'IBFT') {
        value = STRINGS.IBFT_ID
    }
    if (id === 'BI') {
        value = STRINGS.BI_ID
    }
    if (id === 'ATM_WD') {
        value = STRINGS.WD_ID
    }
    if (id === 'UBP') {
        value = STRINGS.UBP_ID
    }
    if (id === 'PP') {
        value = STRINGS.PP_ID
    }
    return value;
}
