import React, { FC, useEffect, useState } from 'react';
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useIntl } from "react-intl";
// import {toAbsoluteUrl} from "../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { StartNewProcessModal } from "../../../_metronic/partials";
// import {getProcessById, getProcessRunsById} from "../../../utils/_requests";
// import {useQuery} from "react-query";
import { STRINGS } from "../../../utils/Enums";
import { AppDispatch, useAppSelector } from "../../../utils/hook";
import { getProcessById, getProcessRunsById, resetProcessRunsListing } from "../../GlobalSlices/global.slice";
import { useDispatch } from "react-redux";
import { convertISODate, formatAMPM, getCurrentProcessId, processingTime } from "../../../utils/DefaultFunctions";
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DefaultSpinner from '../../SharedComponent/DefaultSpinner/DefaultSpinner';

type Props = {
    startNewProcess: () => void,
    dispatch: () => void,
    processRunsList: [],
    currentId: [],
}

const popover = (title: any) => (
    <Popover id="popover-basic">
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
            {title}
        </Popover.Body>
    </Popover>
);

const OneLinkComponent = ({ startNewProcess, processRunsList, dispatch, currentId }: Props) => (
    <div id="kt_app_content" className="app-content flex-column-fluid" style={{ height: '100vh' }}>
        {/*begin::Content container*/}
        <div id="kt_app_content_container" className="app-container container-xx">
            {/*begin::Tables Widget 12*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Header*/}
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Process List</span>
                    </h3>
                    <div className="card-toolbar">
                        <i className={`fa fa-refresh`} onClick={() => {
                            dispatch()
                        }} style={{ color: "#18a689", fontSize: "24px", cursor: "pointer", margin: "0 1rem" }} />
                        {/*begin::Menu*/}
                        <a href="#" className="btn btn-sm fw-bold btn-"
                            style={{ backgroundColor: '#18a689', color: 'white' }}
                            data-bs-toggle="modal" data-bs-target="#kt_modal_new_target"
                            onClick={() => startNewProcess()}>New</a>
                        {/*<button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"*/}
                        {/*        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"*/}
                        {/*        onClick={() => startNewProcess()}>*/}
                        {/*    /!*begin::Svg Icon | path: icons/duotune/general/gen024.svg*!/*/}
                        {/*    <span className="svg-icon svg-icon-2">*/}
                        {/*								<svg xmlns="http://www.w3.org/2000/svg" width="24px"*/}
                        {/*                                     height="24px" viewBox="0 0 24 24">*/}
                        {/*									<g stroke="none" stroke-width="1" fill="none"*/}
                        {/*                                       fill-rule="evenodd">*/}
                        {/*										<rect x="5" y="5" width="5" height="5" rx="1"*/}
                        {/*                                              fill="currentColor"/>*/}
                        {/*										<rect x="14" y="5" width="5" height="5" rx="1"*/}
                        {/*                                              fill="currentColor" opacity="0.3"/>*/}
                        {/*										<rect x="5" y="14" width="5" height="5" rx="1"*/}
                        {/*                                              fill="currentColor" opacity="0.3"/>*/}
                        {/*										<rect x="14" y="14" width="5" height="5" rx="1"*/}
                        {/*                                              fill="currentColor" opacity="0.3"/>*/}
                        {/*									</g>*/}
                        {/*								</svg>*/}
                        {/*							</span>*/}
                        {/*    /!*end::Svg Icon*!/*/}
                        {/*</button>*/}
                        {/*begin::Menu 2*/}
                        {/*<div*/}
                        {/*    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"*/}
                        {/*    data-kt-menu="true">*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3">*/}
                        {/*        <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*    /!*begin::Menu separator*!/*/}
                        {/*    <div className="separator mb-3 opacity-75"></div>*/}
                        {/*    /!*end::Menu separator*!/*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3">*/}
                        {/*        <a href="#" className="menu-link px-3">New Ticket</a>*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3">*/}
                        {/*        <a href="#" className="menu-link px-3">New Customer</a>*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3" data-kt-menu-trigger="hover"*/}
                        {/*         data-kt-menu-placement="right-start">*/}
                        {/*        /!*begin::Menu item*!/*/}
                        {/*        <a href="#" className="menu-link px-3">*/}
                        {/*            <span className="menu-title">New Group</span>*/}
                        {/*            <span className="menu-arrow"></span>*/}
                        {/*        </a>*/}
                        {/*        /!*end::Menu item*!/*/}
                        {/*        /!*begin::Menu sub*!/*/}
                        {/*        <div className="menu-sub menu-sub-dropdown w-175px py-4">*/}
                        {/*            /!*begin::Menu item*!/*/}
                        {/*            <div className="menu-item px-3">*/}
                        {/*                <a href="#" className="menu-link px-3">Admin Group</a>*/}
                        {/*            </div>*/}
                        {/*            /!*end::Menu item*!/*/}
                        {/*            /!*begin::Menu item*!/*/}
                        {/*            <div className="menu-item px-3">*/}
                        {/*                <a href="#" className="menu-link px-3">Staff Group</a>*/}
                        {/*            </div>*/}
                        {/*            /!*end::Menu item*!/*/}
                        {/*            /!*begin::Menu item*!/*/}
                        {/*            <div className="menu-item px-3">*/}
                        {/*                <a href="#" className="menu-link px-3">Member Group</a>*/}
                        {/*            </div>*/}
                        {/*            /!*end::Menu item*!/*/}
                        {/*        </div>*/}
                        {/*        /!*end::Menu sub*!/*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3">*/}
                        {/*        <a href="#" className="menu-link px-3">New Contact</a>*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*    /!*begin::Menu separator*!/*/}
                        {/*    <div className="separator mt-3 opacity-75"></div>*/}
                        {/*    /!*end::Menu separator*!/*/}
                        {/*    /!*begin::Menu item*!/*/}
                        {/*    <div className="menu-item px-3">*/}
                        {/*        <div className="menu-content px-3 py-3">*/}
                        {/*            <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    /!*end::Menu item*!/*/}
                        {/*</div>*/}
                        {/*end::Menu 2*/}
                        {/*end::Menu*/}
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body py-3">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                                <tr className="fw-bold text-muted bg-light">
                                    <th className="min-w-20px">ID</th>
                                    <th className="ps-4 min-w-100px rounded-start">Process By</th>
                                    <th className="min-w-100px">Date</th>
                                    <th className="min-w-100px">Status</th>
                                    <th className="min-w-100px">Start Time</th>
                                    <th className="min-w-100px">Process Time</th>
                                    <th className="min-w-100px">Details</th>
                                </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                                {
                                    processRunsList.length !== 0 &&
                                    processRunsList?.map((mp: any) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <a className="text-dark fw-bold d-block mb-1 fs-6">{mp.id}</a>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {/*<div className="symbol symbol-50px me-5">*/}
                                                        {/*					<span className="symbol-label bg-light">*/}
                                                        {/*						<img*/}
                                                        {/*                            src={`${toAbsoluteUrl('media/svg/avatars/001-boy.svg')}`}*/}
                                                        {/*                            className="h-75 align-self-end" alt=""/>*/}
                                                        {/*					</span>*/}
                                                        {/*</div>*/}
                                                        <div className="d-flex justify-content-start flex-column">
                                                            <a className="text-dark fw-bold mb-1 fs-6">{mp.createdBy}</a>
                                                            {/*<span*/}
                                                            {/*    className="text-muted fw-semibold text-muted d-block fs-7">Manager</span>*/}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a className="text-dark fw-bold d-block mb-1 fs-6">{convertISODate(mp.startTime)}</a>
                                                </td>
                                                <td>
                                                    {
                                                        mp.status === 1 ? <span className="badge badge-warning"
                                                            style={{ backgroundColor: "#ff9122" }}>In-Progress</span> :
                                                            mp.status === 3 ? <span className="badge badge-danger">In-Completed</span> :
                                                                <span className="badge badge-success">Completed</span>
                                                    }
                                                </td>
                                                <td>
                                                    <a className="text-dark fw-bold d-block mb-1 fs-6">{formatAMPM(mp.startTime)}</a>
                                                </td>
                                                <td>

                                                    {
                                                        (mp.status === 2) || (mp.status === 3) ? <a className="text-dark fw-bold d-block mb-1 fs-6">{processingTime(mp.startTime, mp.endTime === null ? mp.startTime : mp.endTime)}</a>
                                                            :
                                                            <div className="progress mb-3">
                                                                <div
                                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                                    role="progressbar"
                                                                    // aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                                                    style={{
                                                                        width: "60%",
                                                                        backgroundColor: "#ff9122",
                                                                        color: "black"
                                                                    }}>
                                                                    <strong>{processingTime(mp.startTime, mp.endTime === null ? mp.startTime : mp.endTime)}</strong>
                                                                </div>
                                                            </div>
                                                    }


                                                </td>
                                                <td>
                                                    {
                                                        mp.status === 1 ?
                                                            <a className="text-dark fw-bold  d-block mb-1 fs-6">Processing</a> :
                                                            mp.status === 3 ?
                                                                <a className="text-dark fw-bold  d-block mb-1 fs-6">Failed</a> :
                                                                <Link
                                                                    to={`${STRINGS.ROUTES.ONE_LINK_PROCESS_DETAIL}/overview/${mp.id}?id=${getCurrentProcessId(currentId)}`}
                                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                                                                    style={{
                                                                        backgroundColor: "#18a689", color: "white"
                                                                    }}>Details</Link>}
                                                </td>
                                                <td>
                                                    {
                                                        <OverlayTrigger placement="top" overlay={popover(mp.statusMessage !== null ? mp.statusMessage : "No message found")}>
                                                            <img src={toAbsoluteUrl('/media/icons/info.png')} style={{ cursor: 'pointer' }} alt="" width="31px" height="31px" />
                                                        </OverlayTrigger>

                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }).reverse()
                                }
                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">P1403</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <div className="d-flex align-items-center">*/}
                                {/*            <div className="symbol symbol-50px me-5">*/}
                                {/*											<span className="symbol-label bg-light">*/}
                                {/*												<img*/}
                                {/*                                                    src={`${toAbsoluteUrl('media/svg/avatars/001-boy.svg')}`}*/}
                                {/*                                                    className="h-75 align-self-end" alt=""/>*/}
                                {/*											</span>*/}
                                {/*            </div>*/}
                                {/*            <div className="d-flex justify-content-start flex-column">*/}
                                {/*                <a className="text-dark fw-bold mb-1 fs-6">Furqan</a>*/}
                                {/*                <span*/}
                                {/*                    className="text-muted fw-semibold text-muted d-block fs-7">Manager</span>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">02-02-2022</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge badge-warning"*/}
                                {/*              style={{backgroundColor: "#ff9122"}}>In-Progress</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">01:36 PM</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <div className="progress mb-3">*/}
                                {/*            <div className="progress-bar progress-bar-striped progress-bar-animated"*/}
                                {/*                 role="progressbar"*/}
                                {/*                // aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"*/}
                                {/*                 style={{width: "60%", backgroundColor: "#ff9122", color: "black"}}>*/}
                                {/*                <strong>3*/}
                                {/*                    minutes</strong>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold  d-block mb-1 fs-6">Processing</a>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">P1402</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <div className="d-flex align-items-center">*/}
                                {/*            <div className="symbol symbol-50px me-5">*/}
                                {/*											<span className="symbol-label bg-light">*/}
                                {/*												<img*/}
                                {/*                                                    src={`${toAbsoluteUrl('media/svg/avatars/001-boy.svg')}`}*/}
                                {/*                                                    className="h-75 align-self-end" alt=""/>*/}
                                {/*											</span>*/}
                                {/*            </div>*/}
                                {/*            <div className="d-flex justify-content-start flex-column">*/}
                                {/*                <a href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6">Amir*/}
                                {/*                    Faizan</a>*/}
                                {/*                <span*/}
                                {/*                    className="text-muted fw-semibold text-muted d-block fs-7">Manager</span>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">01-02-2022</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <span className="badge badge-success">Completed</span>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">11:05 AM</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <a className="text-dark fw-bold d-block mb-1 fs-6">12:36 PM</a>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <Link to="/process/details/overview"*/}
                                {/*              className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"*/}
                                {/*              style={{*/}
                                {/*                  backgroundColor: "#18a689", color: "white"*/}
                                {/*              }}>Details</Link>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                </div>
                {/*begin::Body*/}
            </div>
        </div>
        {/*end::Content container*/}
    </div>
)

const oneLinkBreadcrumbs: Array<PageLink> = [
    // {
    //     title: "IBFT",
    //     path: "/oneLink/IBFT",
    //     isSeparator: false,
    //     isActive: false
    // }
    // , {
    //     title: "1LINK",
    //     path: "/oneLink",
    //     isSeparator: true,
    //     isActive: false
    // }
]

type Slices = { globalSlice: any; };

const OneLinkWrapper: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const intl = useIntl();
    const [showModal, setModal] = useState<boolean>(false);
    const { globalSlice } = useAppSelector(({ Reducers }) => Reducers as Slices)
    useEffect(() => {
        // if (globalSlice.processRunsList.length === 0) {

        // }
        dispatch(getProcessById(`${globalSlice.currentProcessId}`))
        dispatch(getProcessRunsById(`${globalSlice.currentProcessId}`))
        return () => {
            dispatch(resetProcessRunsListing())
        }
    }, [globalSlice.currentProcessId])

    return (
        <>
            <PageTitle breadcrumbs={oneLinkBreadcrumbs}>1LINK - {globalSlice.currentProcessTitle}</PageTitle>
            {
                !globalSlice.isResponsePending ?
                    (<OneLinkComponent startNewProcess={() => {
                        // getProcessById(`${STRINGS.IBFT_ID}`)
                        setModal(true)
                    }} dispatch={() => {
                        dispatch(getProcessRunsById(`${globalSlice.currentProcessId}`))
                    }} processRunsList={globalSlice.processRunsList} currentId={globalSlice.currentProcessId} />)
                    : <DefaultSpinner />
            }
            <StartNewProcessModal show={showModal} handleClose={() => setModal(false)} ID={`${globalSlice.currentProcessId}`} />
        </>
    );
};

export { OneLinkWrapper };