/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { STRINGS } from "../../../../utils/Enums";
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../utils/hook'
import { setUpCurrentProcessId } from '../../../../app/GlobalSlices/global.slice'

export function AsideMenuMain() {
    const intl = useIntl();
    const dispatch = useDispatch<AppDispatch>();
    let current_id = STRINGS.IBFT_ID;
    let current_title = 'IBFT';
    let currentParamsId = new URL(window.location as any).searchParams.get('id')

    if (window.location.pathname.split('/')[2] !== undefined) {
        if (window.location.pathname.split('/')[2] === "IBFT") {
            current_id = STRINGS.IBFT_ID;
            current_title = 'IBFT';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (window.location.pathname.split('/')[2] === "BI") {
            current_id = STRINGS.BI_ID;
            current_title = 'Balance Inquiry';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (window.location.pathname.split('/')[2] === "ATM_WD") {
            current_id = STRINGS.WD_ID;
            current_title = 'ATM Withdrawls';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (window.location.pathname.split('/')[2] === "UBP") {
            current_id = STRINGS.UBP_ID;
            current_title = 'Utility Bill Payment';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (window.location.pathname.split('/')[2] === "PP") {
            current_id = STRINGS.PP_ID;
            current_title = 'PayPak';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
    }

    if (currentParamsId !== undefined) {
        if (currentParamsId === "IBFT") {
            current_id = STRINGS.IBFT_ID;
            current_title = 'IBFT';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (currentParamsId === "BI") {
            current_id = STRINGS.BI_ID;
            current_title = 'Balance Inquiry';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (currentParamsId === "ATM_WD") {
            current_id = STRINGS.WD_ID;
            current_title = 'ATM Withdrawls';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (currentParamsId === "UBP") {
            current_id = STRINGS.UBP_ID;
            current_title = 'Utility Bill Payment';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
        if (currentParamsId === "PP") {
            current_id = STRINGS.PP_ID;
            current_title = 'PayPak';
            dispatch(setUpCurrentProcessId({ currentProcessId: current_id, currentProcessTitle: current_title }))
        }
    }


    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen025.svg'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
                id={STRINGS.IBFT_ID}
                pageLabel={"DASHBOARD"}
            />
            {/*<AsideMenuItem*/}
            {/*  to='/builder'*/}
            {/*  icon='/media/icons/duotune/general/gen019.svg'*/}
            {/*  title='Layout Builder'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Process</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to={`#`}
                title='1LINK'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
            >
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='IBFT' pageLabel={current_title} hasBullet={true} id={current_id} />
                <AsideMenuItem to={`${STRINGS.ROUTES.BI}`} title='Balance Inquiry' pageLabel={current_title} hasBullet={true} id={current_id} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ATM_WD}`} title='ATM Withdrawls' pageLabel={current_title} hasBullet={true} id={current_id} />
                
                <AsideMenuItem to={`${STRINGS.ROUTES.PP_LINK}`} title='PayPak' pageLabel={current_title} hasBullet={true} id={current_id} />
                <AsideMenuItem to={`${STRINGS.ROUTES.UBP_LINK}`} title='Utility Bill Payment' pageLabel={current_title} hasBullet={true} id={current_id} />

            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to={`${STRINGS.ROUTES.ONE_LINK}`}
                title='Master Card'
                icon='/media/icons/duotune/communication/com006.svg'
                fontIcon='bi-person'
            >

            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to={`${STRINGS.ROUTES.ONE_LINK}`}
                title='Raast'
                fontIcon='bi-sticky'
                icon='/media/icons/duotune/general/gen040.svg'
            >

            </AsideMenuItemWithSub>

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to={`${STRINGS.ROUTES.ONE_LINK}`}
                title='Settings'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/communication/com012.svg'
            >
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Capture Cards' hasBullet={true} pageLabel={"IBFT"} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Branch Registration' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='ATM Registration' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='CFC Registration' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Zone List' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='User List' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Card Holding' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='User Management' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Roles' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
                <AsideMenuItem to={`${STRINGS.ROUTES.ONE_LINK}`} title='Processes' pageLabel={"IBFT"} hasBullet={true} id={STRINGS.IBFT_ID} />
            </AsideMenuItemWithSub>

            {/*<AsideMenuItem*/}
            {/*  to='/apps/user-management/users'*/}
            {/*  icon='/media/icons/duotune/general/gen051.svg'*/}
            {/*  title='User management'*/}
            {/*  fontIcon='bi-layers'*/}
            {/*/>*/}
            {/*<div className='menu-item'>*/}
            {/*  <div className='menu-content'>*/}
            {/*    <div className='separator mx-1 my-4'></div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='menu-item'>*/}
            {/*  <a*/}
            {/*    target='_blank'*/}
            {/*    className='menu-link'*/}
            {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
            {/*  >*/}
            {/*    <span className='menu-icon'>*/}
            {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
            {/*    </span>*/}
            {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
            {/*  </a>*/}
            {/*</div>*/}
        </>
    )
}
