const DOMAIN_PREFIX = "";

export const STRINGS = {
    IBFT_ID: "ZNJBaQBMy",
    BI_ID: "dZ5p5rVQR",
    WD_ID: "7OgpDwBQy",
    UBP_ID: "rL6BbdV1e",
    PP_ID: "7lqBA3VeA",
    PAGE_INDEX: 1,
    PAGE_SIZE: 10,
    MATCH_TYPE: {
        MATCH: 1,
        UN_MATCH: 2,
        EXCEPTION: 3,
        HOLD_OVER: 4,
        FORCE_MATCH: 5,
        SAF: 6,
    },
    API_STATUS: {
        DEFAULT: 200,
        CREATE: 201,
        BAD_REQUEST: 400,
        FAIL: 1001,
        SUCCESS: 1000,
    },
    DefaultRowObject: {
        CardNumber: null,
        Id: "",
        Stan: "",
        TableName: "",
        TransactionAmount: "",
        TransactionDate: "",
        TransactionTime: ""
    },
    ROUTES: {
        ROOT: `${DOMAIN_PREFIX}/`,
        DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
        ONE_LINK: `${DOMAIN_PREFIX}/oneLink/IBFT`,
        ONE_LINK_PROCESS_DETAIL: `${DOMAIN_PREFIX}/process/details`,
        ATM_WD: `${DOMAIN_PREFIX}/oneLink/ATM_WD`,
        UBP_LINK: `${DOMAIN_PREFIX}/oneLink/UBP`,
        PP_LINK: `${DOMAIN_PREFIX}/oneLink/PP`,
        ATM_WD_PROCESS_DETAIL: `${DOMAIN_PREFIX}/wd_process/details`,
        BI: `${DOMAIN_PREFIX}/oneLink/BI`,
        BI_PROCESS_DETAIL: `${DOMAIN_PREFIX}/bi_process/details`,
    }
}