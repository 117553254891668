import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UnMatchViewDetailModal = ({show, handleClose}: Props) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [data, setData] = useState({})
    const [hasError, setHasError] = useState(false)

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    // const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    //     const updatedData = {...data, ...fieldsToUpdate}
    //     setData(updatedData)
    // }
    //
    const checkAppBasic = (): boolean => {
        // if (!data.appBasic.appName || !data.appBasic.appType) {
        //     return false
        // }
        return true
    }

    const checkAppDataBase = (): boolean => {
        // if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
        //     return false
        // }

        return true
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()
    }

    const nextStep = () => {
        setHasError(false)
        if (!stepper.current) {
            return
        }

        if (stepper.current.getCurrentStepIndex() === 1) {
            if (!checkAppBasic()) {
                setHasError(true)
                return
            }
        }

        if (stepper.current.getCurrentStepIndex() === 3) {
            if (!checkAppDataBase()) {
                setHasError(true)
                return
            }
        }

        stepper.current.goNext()
    }

    const submit = () => {
        window.location.reload()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px'
            show={show}
            onHide={handleClose}
            onEntered={loadStepper}
        >
            <div className="modal-header" style={{backgroundColor: "#18a689"}}>
                <h5 className="modal-title" style={{color: "white"}}>UN-MATCHED TRANSACTION</h5>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className="modal-body">
                <h4>Transaction Details</h4>
                <br></br>
                <div style={{position: "relative", marginBottom: "1rem"}}><i className="fa fa-check-circle"
                                                                             style={{color: '#18a689'}}></i><label
                    style={{marginLeft: "1rem"}}> Found in BPC</label></div>
                <div style={{position: "relative", marginBottom: "1rem"}}><i className="fa fa-check-circle"
                                                                             style={{color: '#18a689'}}></i><label
                    style={{marginLeft: "1rem"}}> Found in 1LINK</label></div>
                <label className="">
                    <div style={{position: "relative", marginBottom: "1rem"}}><i className="fa-solid fa-xmark"
                                                                                 style={{color: 'red'}}></i><label
                        style={{marginLeft: "1rem"}}> Found in EDGE-24</label></div>
                </label>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-" data-bs-dismiss="modal"
                        style={{backgroundColor: '#18a689', color: 'white'}}>More Details
                </button>
            </div>
        </Modal>,
        modalsRoot
    )
}

export
{
    UnMatchViewDetailModal
}
