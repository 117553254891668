import React from 'react';

const DefaultSpinner = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
            height: '90vh'
        }}>
            <span className='spinner-border h-150px w-150px align-middle text-gray-400' />
        </div>
    );
};

export default DefaultSpinner;