export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}
export function getCurrentSearch(search: any) {
  return search.split('id=')[1] !== undefined && search.split('id=')[1]
}

export function checkIsActive(search: string, pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  const currentSearch = getCurrentSearch(search)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }
  if (url.indexOf(currentSearch) > -1) {
    return true
  }

  return false
}
