/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useState, Fragment} from 'react'
import {KTSVG} from '../../../helpers'
import {CreateAppModal, SetTargetModal} from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {STRINGS} from "../../../../utils/Enums";

let linkForNotToShowBtn: Array<any> = [STRINGS.ROUTES.DASHBOARD];

const Toolbar1 = () => {
    const {classes} = useLayout()
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [showSetTargetModal, setShowSetTargetModal] = useState<boolean>(false)
    const location_pathname = window.location.pathname;
    let showButtons = linkForNotToShowBtn.includes(location_pathname);

    return (
        <>
            <div className='toolbar' id='kt_toolbar'>
                {/* begin::Container */}
                <div
                    id='kt_toolbar_container'
                    className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
                >
                    <DefaultTitle/>

                    {/* begin::Actions */}
                    {
                        showButtons && (
                            <div className='d-flex align-items-center py-1'>
                                {/* begin::Wrapper */}
                                <div className='me-4'>
                                    {/* begin::Menu */}
                                    {/*<a*/}
                                    {/*  href='#'*/}
                                    {/*  className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'*/}
                                    {/*  data-kt-menu-trigger='click'*/}
                                    {/*  data-kt-menu-placement='bottom-end'*/}
                                    {/*  data-kt-menu-flip='top-end'*/}
                                    {/*>*/}
                                    {/*  <KTSVG*/}
                                    {/*    path='/media/icons/duotune/general/gen031.svg'*/}
                                    {/*    className='svg-icon-5 svg-icon-gray-500 me-1'*/}
                                    {/*  />*/}
                                    {/*  Filter*/}
                                    {/*</a>*/}

                                    {/* end::Menu */}
                                </div>
                                {/* end::Wrapper */}

                                {/* begin::Button */}

                                {/* <a
                                    className='btn btn-sm cursor-pointer'
                                    id='kt_toolbar_primary_button'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_create_app'
                                    onClick={() => setShowCreateAppModal(true)}
                                >
                                    Rollover
                                </a> */}
                                {/*end::Button*/}

                                {/* <a
                                    className='btn btn-sm btn-primary cursor-pointer'
                                    id='kt_toolbar_primary_button'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_new_target'
                                    onClick={() => setShowSetTargetModal(true)}
                                >
                                    Add Target
                                </a> */}
                            </div>
                        )
                    }
                    {/* end::Actions */}
                </div>
                {/* end::Container */}
            </div>
            {
                showButtons && (
                    <Fragment>
                        <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)}/>
                        <SetTargetModal show={showSetTargetModal} handleClose={() => setShowSetTargetModal(false)}/>
                    </Fragment>
                )
            }
        </>
    )
}

export {Toolbar1}
