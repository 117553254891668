import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG, WithChildren } from '../../../helpers'
import { useLayout } from '../../core'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../utils/hook'
import { setUpCurrentProcessId } from '../../../../app/GlobalSlices/global.slice'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean,
  id: String,
  pageLabel: String,
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  id,
  pageLabel,
}) => {
  const { pathname,search } = useLocation()
  const isActive = checkIsActive(search,pathname, to)
  const { config } = useLayout()
  const { aside } = config
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to} onClick={() => {
        dispatch(setUpCurrentProcessId({ currentProcessId: id, currentProcessTitle: `${pageLabel}`}))
      }}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export { AsideMenuItem }
