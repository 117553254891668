import axios from "axios";

export const BASE_DOMAIN = (() => {
    if (window.location.hostname === "recon.3em.tech" || window.location.hostname === 'localhost')
        return "http://api.recon.3em.tech/";
    else
        // return "http://159.69.72.39:5001";
        // return "http://api.recon.3em.tech/";
        return "http://apiuatrecon.samba.com.pk:8080/";
})();

export const BASE_URL = BASE_DOMAIN;
export const API = axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    httpAgent: true
});
